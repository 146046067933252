import * as React from "react";
import styled from "@emotion/styled";
// import InnerContainer from "../../components/InnerContainer";
import InsightCardLarge from "../../components/InsightCardLarge";
import InsightCardSmall from "../../components/InsightCardSmall.js";
import { AnimatePresence, motion } from "framer-motion";

const ListingContainer = styled.div`
  background-color: ${props => props.theme.colors.lightPeach};
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 80%;
  }
`;
const ListGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  /* max-width: 767px;  */
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 6rem;
  }
`;

const LargeContainer = styled(motion.div)`
  background-color: ${props => props.theme.colors.offWhite};
  margin: 0 1.5rem 5.5rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin: 0;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 6rem;
    grid-column-start: span 2;
  }
`;

const SmallContainer = styled(motion.div)``;

const InsightCardWrapperLarge = styled.div`
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: 60rem;
    margin: auto;
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    max-width: 99rem;
  }
`;

const InsightCardWrapperSmall = styled.div`
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: calc(calc(60rem - 6rem) / 2);
    ${props => (props.isLeftColumn ? `margin-left: auto` : ``)}
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    max-width: calc(calc(99rem - 6rem) / 2);
  }
`;

const Listing = ({ insights }) => {
  const layoutCards = insights.map((insight, i) => {
    // render large card every 5th card
    if (i % 5 === 0) {
      return (
        <LargeContainer key={insight.databaseId} exit={{ opacity: 0 }} layout>
          <InsightCardWrapperLarge>
            <InsightCardLarge insight={insight} />
          </InsightCardWrapperLarge>
        </LargeContainer>
      );
    } else {
      // split the card sets into 2 sets.
      // Set 1: indexes 0 - 4 and Set 2: indexes 5 - 9 .
      // Set 1 indexes have left-side grid column on odd indexes and right-side grid column on even indexes.
      // Set 2 indexes have left-side grid column on even indexes and right-side grid column on odd indexes.
      // pass a prop to style left column differently than right column
      if (i % 10 < 5) {
        // Set 1: indexes 0-4
        return (
          <SmallContainer key={insight.databaseId} exit={{ opacity: 0 }} layout>
            <InsightCardWrapperSmall isLeftColumn={(i % 10) % 2 === 1}>
              <InsightCardSmall insight={insight} />
            </InsightCardWrapperSmall>
          </SmallContainer>
        );
      } else {
        // Set 2: indexes 5-9
        return (
          <SmallContainer key={insight.databaseId} exit={{ opacity: 0 }} layout>
            <InsightCardWrapperSmall isLeftColumn={(i % 10) % 2 === 0}>
              <InsightCardSmall insight={insight} />
            </InsightCardWrapperSmall>
          </SmallContainer>
        );
      }
    }
  });
  return (
    <ListingContainer>
      <ListGrid>
        <AnimatePresence>{layoutCards}</AnimatePresence>
      </ListGrid>
    </ListingContainer>
  );
};
export default React.memo(Listing);
