import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "@emotion/styled";
import Sidebar from "./Sidebar";
import Listing from "./Listing";

const InsightsListingContainer = styled.div`
  background-color: ${props => props.theme.colors.lightPeach};
  /* padding: 1.5rem; */
  position: relative;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    display: flex;
  }
`;
const InsightsListing = () => {
  const [currentInsightFilter, setCurrentInsightFilter] = useState("all");
  const [filteredInsights, setFilteredInsights] = useState([]);
  const queriedInsights = useStaticQuery(postsQuery);
  const insights = queriedInsights.allWpPost.nodes;
  useEffect(() => {
    const filtered =
      currentInsightFilter === "all"
        ? insights
        : insights.filter(insight =>
            insight?.categories?.nodes
              ?.flat()
              ?.some(category => category.databaseId === currentInsightFilter)
          );
    setFilteredInsights(filtered);
  }, [currentInsightFilter, insights]);
  return (
    <InsightsListingContainer>
      <Sidebar
        setCurrentInsightFilter={setCurrentInsightFilter}
        currentInsightFilter={currentInsightFilter}
      />
      <Listing insights={filteredInsights} />
    </InsightsListingContainer>
  );
};

const postsQuery = graphql`
  {
    allWpPost(sort: { fields: date, order: DESC }) {
      nodes {
        databaseId
        title
        date(formatString: "MMM D, YYYY")
        uri
        status
        tags {
          nodes {
            name
            databaseId
          }
        }
        categories {
          nodes {
            name
            databaseId
          }
        }
        terms {
          nodes {
            name
            databaseId
          }
        }
        Insight {
          fieldGroupName
          cardThumbnailDesktop {
            databaseId
            altText
            localFile {
              extension
              publicURL
              relativePath
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          cardThumbnailMobile {
            databaseId
            altText
            localFile {
              extension
              publicURL
              relativePath
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          featuredImageDesktop {
            databaseId
            altText
            localFile {
              extension
              publicURL
              relativePath
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          featuredImageMobile {
            databaseId
            altText
            localFile {
              extension
              publicURL
              relativePath
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          cardCtaText
        }
      }
    }
  }
`;
export default InsightsListing;
