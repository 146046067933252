import * as React from "react";
import styled from "@emotion/styled";
import PlaceholderGraphic from "../icons/Placeholder-graphic.svg";
import Image from "./image";
import { GatsbyImage, withArtDirection, getImage } from "gatsby-plugin-image";
import PageLink from "./PageLink";
import { useTheme } from "@emotion/react";
import CtaButton from "./CtaButton";

const InsightItemBox = styled.div`
  background-color: ${props => props.theme.colors.offWhite};
  margin-bottom: 2.5rem;
  .gatsby-image-wrapper {
    max-height: 300px;
  }
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin: 8rem 0;
    grid-column: 2 / 4;
  }
`;

const InsightItemDate = styled.p`
  font-family: ${props => props.theme.fonts.condensedMedium};
  font-size: 1.3rem;
  line-height: 23px;
  letter-spacing: 1.95px;
  text-transform: uppercase;
  color: ${props => props.theme.colors.darkRed};
  margin-top: 1.75rem;
`;

const InsightItemTitle = styled.h4`
  font-family: ${props => props.theme.fonts.regular};
  font-weight: 500;
  font-size: 3.1rem;
  line-height: 38px;
  color: ${props => props.theme.colors.offBlack};
  margin-top: 1rem;
  // min-height: 65px;
`;

const InsightItemContent = styled.div`
  padding: 0 1rem;
`;

const InsightImage = styled(Image)`
  width: 100%;

  @media (min-width: ${props => props.theme.colors.md}) {
    width: 67%;
  }
`;

const InsightCtaButton = styled(CtaButton)`
  margin-top: 4.5rem;
`;

const InsightCardLarge = ({
  insight: {
    Insight,
    categories,
    databaseId,
    date,
    status,
    tags,
    terms,
    title,
    uri,
  },
  ...props
}) => {
  const theme = useTheme();
  const sources = withArtDirection(
    getImage(Insight.cardThumbnailMobile?.localFile),
    [
      {
        image: getImage(Insight.cardThumbnailDesktop?.localFile),
        media: `(min-width: ${theme.breakpoints.sm})`,
      },
    ]
  );
  return (
    <InsightItemBox>
      <PageLink to={uri}>
        {Insight.cardThumbnailDesktop || Insight.cardThumbnailMobile ? (
          <GatsbyImage
            image={sources}
            backgroundColor={`transparent`}
            alt={`${title} thumbnail image`}
          />
        ) : (
          <InsightImage image={<PlaceholderGraphic />} />
        )}
      </PageLink>
      <InsightItemContent>
        <InsightItemDate>
          {categories.nodes?.map(category => category?.name)?.join(", ")}{" "}
          &mdash; {date}
        </InsightItemDate>
        <InsightItemTitle>
          <PageLink to={uri}>{title}</PageLink>
        </InsightItemTitle>
        <InsightCtaButton
          type="solid"
          color={`purple`}
          link={{ title: Insight.cardCtaText || `read more`, url: uri }}
        />
      </InsightItemContent>
    </InsightItemBox>
  );
};
export default React.memo(InsightCardLarge);
