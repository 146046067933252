import React, { useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
// import { css } from "@emotion/react";
import { graphql, useStaticQuery } from "gatsby";
import { useWindowWidth } from "../../shared/hooks";
import { useTheme } from "@emotion/react";
import DownArrow from "../../icons/down-arrow.svg";
import { motion, AnimatePresence } from "framer-motion";

const SidebarContainer = styled(motion.div)`
  font-family: ${props => props.theme.fonts.condensedMedium};
  font-size: 2rem;
  letter-spacing: 2.4px;
  background-color: ${props => props.theme.colors.lightPeach};
  color: ${props => props.theme.colors.offBlack};
  padding: 4rem 2rem 1rem;
  /* padding: 4rem 4rem 2rem; */
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    position: sticky;
    padding: 0;
    top: 0;
    left: 0;
    width: 20%;
    height: 100%;
    /* width: 20%; */
    /* padding: 7rem; */
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .open {
      display: block;
    }

    .close {
      display: none;
    }
  }
`;

const SideBarWrapper = styled(motion.div)`
  text-align: center;
  text-transform: uppercase;

  .down-arrow {
    height: 28px;
    width: 28px;
    padding: 8px;
    position: absolute;
    right: -44px;
    top: 50%;
    transform: translateY(-50%);

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      display: none;
    }

    &-down {
      transform: scaleY(-1) translateY(50%);
    }
  }
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-left: 15%;
    margin-top: 5rem;
    max-width: 160px;
    text-align: unset;
  }
`;

const SideBarFilterButtonCurrent = styled.button`
  position: relative;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-family: ${props => props.theme.fonts.condensedMedium};
  line-height: 71px;
  letter-spacing: 2.4px;
  position: relative;
  margin-bottom: 2rem;
  outline: none;
  &:focus {
    outline: none;
  }
  &::after {
    content: "";
    bottom: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 40%;
    border-bottom: 1px solid red;
  }
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    display: none;
  }
`;

const SideBarFilterButtonItem = styled(motion.button)`
  display: block;
  position: relative;
  outline: none;
  text-transform: uppercase;
  font-family: ${props => props.theme.fonts.condensedMedium};
  font-size: 1.8rem;
  letter-spacing: 2.4px;
  line-height: 36px;
  margin: 0 auto 3rem;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    margin: 0 0 3rem;
  }
  &:hover,
  &:focus {
    cursor: pointer;
    outline: none;
  }
  &.active {
    &::after {
      content: "";
      bottom: 0%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      width: 40%;
      border-bottom: 1px solid red;
    }
    @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
      &::after {
        display: none;
      }
      &::before {
        content: "";
        position: absolute;
        margin: 0 auto;
        bottom: 0;
        width: 40%;
        text-align: center;
        border-bottom: 2px solid red;
      }
    }
  }
`;

const SideBarList = styled(motion.div)``;

const wrapperStates = {
  open: {
    height: `100%`,
  },
  closed: {
    height: `0%`,
  },
};

const listStates = {
  open: {
    height: `100%`,
    transition: { staggerChildren: 0.07 },
  },
  closed: {
    height: `0%`,
    transition: { staggerChildren: 0.05 },
  },
};

const itemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: -50,
    opacity: 0,
    transition: {
      y: { stiffness: 10 },
    },
  },
};

// list of the insights categories...list on desktop...dropdown/select menu on mobile
const Sidebar = ({ currentInsightFilter, setCurrentInsightFilter }) => {
  const theme = useTheme();
  const windowWidth = useWindowWidth();
  const [listOpen, toggleList] = useState(false);
  const queriedCategories = useStaticQuery(categoryQuery);
  const allCategories = useMemo(
    () => [
      { databaseId: "all", name: "All Insights" },
      ...queriedCategories.allWpCategory.nodes,
    ],
    [queriedCategories.allWpCategory.nodes]
  );
  const [categories, setCategories] = useState(allCategories);
  const handleSideBarFilterButtonItemClick = filterId => {
    if (windowWidth < theme.breakpointNumbers.md) {
      setCurrentInsightFilter(filterId);
      toggleList(!listOpen);
    } else {
      setCurrentInsightFilter(filterId);
    }
  };
  // console.log(categories);
  useEffect(() => {
    if (windowWidth < theme.breakpointNumbers.md) {
      setCategories(
        allCategories.filter(
          category => category.databaseId !== currentInsightFilter
        )
      );
    } else {
      setCategories(allCategories);
    }
  }, [
    currentInsightFilter,
    allCategories,
    theme.breakpointNumbers.md,
    windowWidth,
  ]);
  // for position: sticky to work, parents can't have overflow: hidden so we remove it only when this sidebar exists and then reset the overflow on the tl-edges element when unmounting
  useEffect(() => {
    const transitionWrapper = document.getElementsByClassName("tl-edges")[0];
    // only make sidebar sticky on md breakpoint and up.
    if (windowWidth > theme.breakpointNumbers.md) {
      transitionWrapper.style.overflowX = "visible";
    }
    return () => (transitionWrapper.style.overflowX = "hidden");
  }, [theme.breakpointNumbers.md, windowWidth]);
  useEffect(() => {
    // force category list to be open on desktop because it doesn't have dropdown functionality
    if (windowWidth >= theme.breakpointNumbers.md) {
      toggleList(true);
    } else {
      // force dropdown to be closed when transitioning from desktop to mobile
      toggleList(false);
    }
  }, [theme.breakpointNumbers.md, windowWidth]);
  return (
    <SidebarContainer
      initial={false}
      variants={wrapperStates}
      animate={listOpen ? "open" : "closed"}
    >
      <SideBarWrapper variants={wrapperStates}>
        <SideBarFilterButtonCurrent onClick={() => toggleList(!listOpen)}>
          {
            allCategories.find(
              category => category.databaseId === currentInsightFilter
            )?.name
          }
          <DownArrow
            className={`down-arrow ${
              listOpen ? "down-arrow-down" : "down-arrow-up"
            }`}
          />
        </SideBarFilterButtonCurrent>
        <AnimatePresence>
          {listOpen && (
            <SideBarList exit={{ height: 0 }} variants={listStates}>
              {categories.map(category => (
                <SideBarFilterButtonItem
                  key={category.databaseId}
                  variants={itemVariants}
                  onClick={() =>
                    handleSideBarFilterButtonItemClick(category.databaseId)
                  }
                  className={
                    currentInsightFilter === category.databaseId ? "active" : ""
                  }
                >
                  {category.name}
                </SideBarFilterButtonItem>
              ))}
            </SideBarList>
          )}
        </AnimatePresence>
      </SideBarWrapper>
    </SidebarContainer>
  );
};

// only pull in categories that have posts
const categoryQuery = graphql`
  query {
    allWpCategory(filter: { count: { gt: 0 } }) {
      nodes {
        databaseId
        name
        uri
        count
        slug
        posts {
          nodes {
            databaseId
            title
            uri
            categories {
              nodes {
                databaseId
                name
              }
            }
          }
        }
      }
    }
  }
`;
export default Sidebar;
